import type { User } from "@rototip/lib-user/db-client";
import { useSession } from "next-auth/react";
import Script from "next/script";
import { useMemo } from "react";

declare global {
	interface Window {
		_hsq?: Object[];
	}
}

export function signOutHubspotAnalytics() {
	if (
		typeof window !== "undefined" &&
		window._hsq &&
		typeof window._hsq.push === "function"
	) {
		var _hsq = (window._hsq = window._hsq || []);
		_hsq.push(["identify", {}]);
	} else {
		console.warn("Hubspot Analytics is not initialized or does not exist.");
	}
}

const HubspotAnalytics = () => {
	const hubspotId = process.env.NEXT_PUBLIC_HUBSPOT_ID;
	const { data: session } = useSession();

	const identifier = useMemo(() => {
		if (session && session.user) {
			const user = session.user;
			return "impersonatedBy" in user
				? (user.impersonatedBy as User).email
				: user?.email!;
		}
		return undefined;
	}, [session]);

	if (process.env.NODE_ENV === "development") {
		console.debug(
			"Hubspot is disabled in development. Hubspot script will not be loaded."
		);
		return null;
	}

	if (!hubspotId) {
		console.warn(
			"Hubspot Analytics ID is not defined. Hubspot script will not be loaded."
		);
		return null;
	}

	return (
		<>
			<Script
				id="hs-script-loader"
				strategy="afterInteractive"
				src={`//js-eu1.hs-scripts.com/${hubspotId}.js`}
				type="text/javascript"
			/>
			{identifier && (
				<Script
					id="hs-script-config"
					strategy="afterInteractive"
					dangerouslySetInnerHTML={{
						__html: `
              var _hsq = (window._hsq = window._hsq || []);
              _hsq.push(["identify", {
                email: ${JSON.stringify(identifier)},
              }]);
            `,
					}}
				/>
			)}
		</>
	);
};

export default HubspotAnalytics;
